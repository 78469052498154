import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { scaleOrdinal } from 'd3-scale';
import { schemeSet3 } from 'd3-scale-chromatic';
import L from 'leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDistrictData } from '../redux/actions/districtActions'; // Update the import path if needed
import CrimeModal from './details';
import 'leaflet/dist/leaflet.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const ZimbabweMap = () => {
    const mapRef = useRef();
    const geoJsonRef = useRef();
    const dispatch = useDispatch();
    const geoJsonData = useSelector((state) => state.geoJson.data);
    const selectedDistrictData = useSelector((state) => state.district.data);
    const loading = useSelector((state) => state.district.loading);
    const error = useSelector((state) => state.district.error);
    const [showModal, setShowModal] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState({ name: '', type: '' });

    useEffect(() => {
        if (geoJsonData && mapRef.current) {
            const bounds = L.geoJSON(geoJsonData).getBounds();
            mapRef.current.fitBounds(bounds, {
                padding: [50, 50],
                maxZoom: 8  // Adjust this value to control the maximum zoom level
            });
            setTimeout(() => {
                mapRef.current.invalidateSize();
                mapRef.current.fitBounds(bounds, {
                    padding: [50, 50],
                    maxZoom: 8
                });
            }, 100);
        }
    }, [geoJsonData]);

    const colorScale = scaleOrdinal(schemeSet3);

    const style = (feature) => {
        const districtId = feature.properties.district_id || feature.properties.id || Math.floor(Math.random() * 10);
        return {
            fillColor: colorScale(districtId),
            weight: 2,
            opacity: 1,
            color: 'white',
            dashArray: '3',
            fillOpacity: 0.7
        };
    };

    const highlightFeature = (e) => {
        const layer = e.target;
        layer.setStyle({
            weight: 5,
            color: '#666',
            dashArray: '',
            fillOpacity: 0.7
        });
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
        }
    };

    const resetHighlight = (e) => {
        geoJsonRef.current.resetStyle(e.target);
    };

    const zoomToFeature = (e) => {
        mapRef.current.fitBounds(e.target.getBounds());
    };

    const onEachFeature = (feature, layer) => {
        layer.on({
            click: (e) => {
                highlightFeature(e);
                zoomToFeature(e);
                console.log(`Polygon Data: ${JSON.stringify(feature.properties)}`)
                const districtId = feature.properties.districtpc || feature.properties.districtpc; // Make sure this property matches your GeoJSON data
                const districtName = feature.properties.district || 'Unknown District'; // Adjust property name as needed
                const districtType = feature.properties.districtyp || 'Unknown Type';
                setSelectedDistrict({
                    name: districtName,
                    type: districtType
                });
                sessionStorage.setItem('currentDistrict', districtId);
                dispatch(fetchDistrictData({ districtId, districtName }));
                setShowModal(true);
            },
            mouseover: highlightFeature,
            mouseout: resetHighlight
        });
    };

    return (
        <div>
            <div id="map" style={{ height: '80vh', width: '100%' }}>
                <MapContainer center={[-19.0154, 29.1549]} zoom={6} style={{ height: '100%', width: '100%' }} ref={mapRef}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {geoJsonData && (
                        <GeoJSON
                            data={geoJsonData}
                            style={style}
                            onEachFeature={onEachFeature}
                            ref={geoJsonRef}
                        />
                    )}
                </MapContainer>
            </div>
            <CrimeModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                districtName={selectedDistrict.name}
                districtType={selectedDistrict.type}
                loading={loading}
                error={error}
                districtData={selectedDistrictData}
            />
            <footer style={{ height: '2vh', textAlign: 'center' }}>
                <p>Powered by SMTECH - Software - Engineering &copy; 2024</p>
            </footer>
        </div>
    );
};

export default ZimbabweMap;
